<template>
  <v-layout wrap>
    <v-form v-if="selectedProject == null">
      <v-container fluid grid-list>
        <v-layout wrap>
          <v-flex xs12 sm12 md12 lg12 xl12>
            <v-text-field
              v-model="client.name"
              :error-messages="nameErrors"
              :counter="100"
              label="Naam"
              required
              @input="$v.client.name.$touch()"
              @blur="$v.client.name.$touch()"
            ></v-text-field>
          </v-flex>
        <v-flex xs12 sm12 md12 lg12 xl12 d-flex v-if="!client.isBaseOrganization">
          <v-select
            v-model="client.parentOrganizationId"
            :items="clients"
            item-text="name"
            item-value="id"
            label="Koppelen aan"
          ></v-select>
        </v-flex>
          <v-flex xs12 sm12 md12 lg12 xl12>
            <v-btn color="primary" @click="submit">opslaan</v-btn>
            <v-btn color="primary" @click="back">annuleren</v-btn>
            <span>{{submitStatus}}</span>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>
    <v-flex xs12 sm12 md12 lg12 xl12 v-if="selectedProject == null">
      <v-layout justify-end>
        <v-btn @click="addProject" color="success" small dark>
          nieuw project
          <v-icon right dark>add</v-icon>
        </v-btn>
      </v-layout>
    </v-flex>
    <v-flex xs12 sm12 md12 lg12 xl12 v-if="selectedProject == null && projects.length > 0">
      <h3>Projecten</h3>
      <v-list>
        <v-list-tile
          v-for="project in projects"
          :key="project.id"
          avatar
          @click="selectedProject = project">
          <v-list-tile-content>
            <v-list-tile-title v-text="project.name"></v-list-tile-title>
          </v-list-tile-content>
          <v-list-tile-avatar>
            <v-icon>work</v-icon>
          </v-list-tile-avatar>
        </v-list-tile>
      </v-list>        
    </v-flex>
    <projectForm 
      v-if="selectedProject != null" 
      :project="selectedProject" 
      v-on:done="getProjects"
      v-on:reset="getProjects">
    </projectForm>
  </v-layout>
</template>

<script>
  import projectForm from './ProjectForm.vue'
    import { validationMixin } from 'vuelidate'
  import { required, maxLength } from 'vuelidate/lib/validators'

  export default {
    components: { 
      projectForm
    },
    props: ['client'],
    mixins: [validationMixin],
    validations: {
      client: {
        name: { required, maxLength: maxLength(100) },
        description: { maxLength: maxLength(200) }
      }
    },

    data: () => ({
      selectedProject: null,
      projects: [],
      submitStatus: null,
      clients: []
    }),

    computed: {      
      nameErrors () {
        const errors = []
        if (!this.$v.client.$dirty) return errors
        !this.$v.client.name.maxLength && errors.push('Naam kan maximaal 100 tekens bevatten')
        !this.$v.client.name.required && errors.push('Dit veld is verplicht')
        return errors
      }    
    },
    methods: {
      getProjects () {
        this.selectedProject = null
        this.$http.get('api/project/GetByOrganization/' + this.client.id)
          .then(request => this.setProjects(request.data))
          .catch(() => { console.error('api error!') }) /* eslint-disable-line no-console */
      },
      setProjects (data) {
        this.projects = data
      },
      addProject () {
        this.projects.push({
          name: ''
        })
        this.selectedProject = this.projects[this.projects.length-1]
      },
      getOrganization () {
        this.$http.get('api/organization/')
          .then(requestOrg => {
            this.clients.push(requestOrg.data)
            this.$http.get('api/client/')
              .then(requestClients => {
                if (requestClients.data !== undefined && requestClients.data.length > 0) {
                  this.clients = this.clients.concat(requestClients.data)
                }
                const index = this.clients.map(e => e.id).indexOf(this.client.id);
                if (index > -1) {
                  this.clients.splice(index, 1);
                }
              })
              .catch(() => { console.error('api error!') }) /* eslint-disable-line no-console */
          })
          .catch(() => { console.error('api error!') }) /* eslint-disable-line no-console */
      },
      submit() {
        this.$v.client.$touch()
        if (this.$v.$invalid) {
          this.submitStatus = 'ERROR'
        } else {
          if (this.client.id == undefined || this.client.id == '00000000-0000-0000-0000-000000000000') {
          this.submitStatus = 'POSTING'
            this.$http.post('api/client/', this.client)
              .then(request => {
                if (request.status == 201) {
                  this.client.id = request.data
                  this.submitStatus = 'OK'
                  this.$emit('done')
                } else {
                  this.submitStatus = 'ERROR'
                }
              })
              .catch(() => { 
                console.error('post error!') /* eslint-disable-line no-console */
                this.submitStatus = 'ERROR'
              })
          } else {
          this.submitStatus = 'PUTTING'
            this.$http.put('api/client/' + this.client.id, this.client)
              .then(request => {
                if (request.status == 201) {
                  this.client.id = request.data
                  this.submitStatus = 'OK'
                  this.$emit('done')
                } else {
                  this.submitStatus = 'ERROR'
                }
              })
              .catch(() => { 
                console.error('put error!') /* eslint-disable-line no-console */
                this.submitStatus = 'ERROR'
              })
          }
        }
      },
      back () {
        this.$v.$reset()
        this.$emit('done')
      },
      save() {
      }  
    },
    mounted: function() { 
      this.getOrganization()
      this.getProjects()
    } 
  }
</script>