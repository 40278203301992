<template>
  <v-layout row wrap>
    <v-flex xs12 sm12 md12 lg12 xl12 v-if="selectedClient == null">
      <v-layout justify-end>
        <v-btn @click="addClient" color="success" small dark>
          nieuwe klant
          <v-icon right dark>add</v-icon>
        </v-btn>
      </v-layout>
    </v-flex>
    <v-flex xs12 sm12 md12 lg12 xl12 v-if="selectedClient == null && clients.length > 0">
      <v-treeview 
        :items="clients"
        open-all
        activatable
        :active.sync="active"
      ></v-treeview>
    </v-flex>
    <clientForm 
      v-if="selectedClient"
      :client="selectedClient"
      v-on:done="active = []">
    </clientForm>
  </v-layout>
</template>

<script>
import { mapGetters } from 'vuex'
import clientForm from '../../components/ClientForm.vue'
export default {
  name: 'Organization',
  components: { 
    clientForm
  },
  data () {
    return {
      flatClients: [],
      active: []
    }
  },
  computed: {
    ...mapGetters([
      'oidcUser'
    ]),
    clients() {
      return this.flatClients ? this.unflatten(this.flatClients) : []
    },
    selectedClient () {
      if (!this.active.length) return undefined
      const id = this.active[0]
      var result = this.flatClients.find(client => client.id === id)
      return result
    }
  },
  methods: {
    getClients () {
      this.$http.get('api/client/')
        .then(request => {
          this.flatClients = request.data
        })
        .catch(() => { console.error('api error!') }) /* eslint-disable-line no-console */
    },
    addClient () {
      this.flatClients.push({
        name: '',
        id: '00000000-0000-0000-0000-000000000000',
        parentOrganizationId: this.flatClients[0].id
      })
      this.active = ['00000000-0000-0000-0000-000000000000']
    },
    unflatten(array, parent, tree) {
      var that = this
      tree = typeof tree !== 'undefined' ? tree : []
      parent = typeof parent !== 'undefined' ? parent : { id: null }

      var children = array.filter(function(child){ return child.parentOrganizationId === parent.id })

      if (children.length) {
        if (!parent.id) {
          tree = children
        }else{
          parent['children'] = children
        }
        children.forEach(function(child){ that.unflatten( array, child ) } )                  
      } else { parent['children'] = [] }

      return tree
    }    
  },
  mounted () {
    this.getClients()
  }
}
</script>