<template>
  <v-form>
    <v-container fluid  grid-list>
      <v-layout wrap>
        <v-flex xs12 sm12 md12 lg12 xl12>
          <v-text-field
            v-model="project.name"
            :error-messages="nameErrors"
            :counter="100"
            label="Projectnaam"
            required
            @input="$v.project.name.$touch()"
            @blur="$v.project.name.$touch()"
          ></v-text-field>
        </v-flex>
        <v-flex xs6 sm6 md3>
          <v-dialog
            ref="dialog1"
            v-model="modalStart"
            :return-value.sync="project.startDate"
            persistent
            lazy
            full-width
            width="290px"
          >
            <v-text-field
              slot="activator"
              v-model="project.startDate"
              label="Start datum"
              prepend-icon="event"
              readonly
            ></v-text-field>
            <v-date-picker 
              locale="nl-nl"
              v-model="project.startDate" 
              @input="$refs.dialog1.save(project.startDate)" 
              scrollable>
            </v-date-picker>
          </v-dialog>
        </v-flex>
        <v-flex xs6 sm6 md3>
          <v-dialog
            ref="dialog2"
            v-model="modalEnd"
            :return-value.sync="project.endDate"
            persistent
            lazy
            full-width
            width="290px">
            <v-text-field
              slot="activator"
              v-model="project.endDate"
              label="Eind datum"
              prepend-icon="event"
              readonly
            ></v-text-field>
            <v-date-picker 
              locale="nl-nl"
              v-model="project.endDate" 
              @input="$refs.dialog2.save(project.endDate)" 
              scrollable>
            </v-date-picker>
          </v-dialog>
        </v-flex>
        <v-flex xs6 sm6 md3>
          <v-text-field
            v-model="project.hourBudgetAdministrative"
            mask="###"
            label="Urenbudget PM"
          ></v-text-field>
        </v-flex>
        <v-flex xs6 sm6 md3>
          <v-text-field
            v-model="project.hourBudgetBillable"
            mask="###"
            label="Urenbudget"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 md6 d-flex>
          <v-autocomplete
            :items="items"
            item-text="name"
            item-value="id"
            :search-input.sync="search"
            v-model="project.organizationId"
            flat
            solo
            hide-no-data
            hide-details
            label="Klant"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs12 md6 d-flex>
          <v-select
            v-model="project.type" 
            :items="types"
            item-text="name"
            item-value="id"
            required
            label="Projecttype"
          ></v-select>
        </v-flex>
        <v-flex xs12 md6 d-flex>
          <v-select
            v-model="selectedProducts"
            :items="products"
            item-text="name"
            item-value="id"
            attach
            chips
            label="Product(en)"
            multiple
          ></v-select>
        </v-flex>
        <v-flex xs12 md6 d-flex>
          <v-select
            v-model="selectedEmployees"
            :items="employees"
            :item-text="getEmployeeName"
            item-value="id"
            attach
            chips
            label="Medewerker(s)"
            multiple>
          </v-select>
        </v-flex>
        <v-flex xs12 md6 d-flex>
          <v-select
            v-model="project.managerId"
            :items="employees"
            :item-text="getEmployeeName"
            item-value="id"
            label="Manager">
          </v-select>
        </v-flex>        
        <v-flex xs12 sm12 md12 lg12 xl12>
          <v-btn color="primary" @click="submit">opslaan</v-btn>
          <v-btn color="primary" @click="back">annuleren</v-btn>
          <span>{{submitStatus}}</span>
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
    import { validationMixin } from 'vuelidate'
  import { required, maxLength } from 'vuelidate/lib/validators'

  export default {
    props: ['project'],
    mixins: [validationMixin],
    validations: {
      project: {
        name: { required, maxLength: maxLength(100) },
        description: { maxLength: maxLength(200) },
        organizationId: { required },
        type: { required }
      }
    },

    data: () => ({
      search: null,
      items: [],
      date: new Date().toISOString().substr(0, 10),
      submitStatus: null,
      modalStart: false,
      modalEnd: false,
      products: [],
      clients: [],
      employees: [],
      selectedProducts: [],
      selectedEmployees: [],
      types: [
        {id: 0, name: 'Administrative'}, 
        {id: 1, name: 'ProductDevelopment'}, 
        {id: 2, name: 'Billable'}, 
        {id: 3, name: 'Service'}
        ]
    }),

    computed: {      
      dateFormatted() { 
        var date = new Date().toISOString().substr(0, 10)
        const [year, month, day] = date.split('-')
        return `${day}-${month}-${year}`         
        },   
      nameErrors () {
        const errors = []
        if (!this.$v.project.$dirty) return errors
        !this.$v.project.name.maxLength && errors.push('Projectnaam kan maximaal 100 tekens bevatten')
        !this.$v.project.name.required && errors.push('Dit veld is verplicht')
        return errors
      },
      typeErrors () {
        const errors = []
        if (!this.$v.project.$dirty) return errors
        !this.$v.project.type.required && errors.push('Dit veld is verplicht')
        return errors
      },
      computedDateFormatted () {
        return this.formatDate(this.date)
      }      
    },
    watch: {
      search (val) {
        if (val == '') this.items = this.clients
        val && val !== this.select
      },
      date (val) {
        this.dateFormatted = this.formatDate(val)
      }
    },
    methods: {
      getEmployees () {
        this.$http.get('api/employees/')
          .then(request => this.employees = request.data)
          .catch(() => { console.error('api error!') }) /* eslint-disable-line no-console */
      },
      getEmployeeName (item) {
        return ((item.firstname || '') + ' ' + (item.surnamePrefix || '') + ' ' +  (item.surname || '')).replace('  ', ' ')
      },
      getEmployeeValue (item) {
        return { 'projectId': this.project.id, 'employeeId': item.id }
      },
      getProductValue (item) {
        return { 'projectId': this.project.id, 'productId': item.id } 
      },
      getClients () {
        this.$http.get('api/organization/')
          .then(requestOrg => {
            this.clients.push(requestOrg.data)
            this.$http.get('api/client/')
              .then(requestClients => {
                if (requestClients.data !== undefined && requestClients.data.length > 0) {
                  this.clients = this.clients.concat(requestClients.data)
                  this.items = this.clients
                }
              })
              .catch(() => { console.error('api error!') }) /* eslint-disable-line no-console */
          })
          .catch(() => { console.error('api error!') }) /* eslint-disable-line no-console */
      },
      getProducts () {
        this.$http.get('api/product/')
          .then(request => this.products = request.data)
          .catch(() => { console.error('api error!') }) /* eslint-disable-line no-console */
      },
      submit() {
        this.$v.project.$touch()
        if (this.$v.project.$invalid) {
          this.submitStatus = 'ERROR'
        } else {
          this.project.projectEmployees = this.selectedEmployees.map(e => ({'projectId': this.project.id, 'employeeId': e}))
          this.project.projectProducts = this.selectedProducts.map(e => ({'projectId': this.project.id, 'productId': e}))
          if (this.project.id == undefined) {
            this.submitStatus = 'POSTING'
            this.$http.post('api/project/', this.project)
              .then(request => {
                if (request.status == 201) {
                  this.submitStatus = 'OK'
                  this.$emit('done')
                } else {
                  this.submitStatus = 'ERROR'
                }
              })
              .catch(() => { 
                console.error('post error!') /* eslint-disable-line no-console */
                this.submitStatus = 'ERROR'
              })
          } else {
          this.submitStatus = 'PUTTING'
            this.$http.put('api/project/' + this.project.id, this.project)
              .then(request => {
                if (request.status == 201) {
                  this.submitStatus = 'OK'
                  this.$emit('done')
                } else {
                  this.submitStatus = 'ERROR'
                }
              })
              .catch(() => { 
                console.error('put error!') /* eslint-disable-line no-console */
                this.submitStatus = 'ERROR'
              })
          }
        }
      },
      back () {
        this.$v.$reset()
        this.$emit('reset')
      },
      save() {     
      },
      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}-${month}-${year}`
      },
      parseDate (date) {
        if (!date) return null

        const [day, month, year] = date.split('-')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      }      
    },
    mounted: function() {
      this.getEmployees()
      this.getClients()
      this.getProducts()
      if (this.project.startDate !== null && this.project.startDate !== undefined) this.project.startDate = this.project.startDate.substr(0, 10)
      if (this.project.endDate !== null && this.project.endDate !== undefined) this.project.endDate = this.project.endDate.substr(0, 10)
      if (this.project.projectEmployees !== null && this.project.projectEmployees !== undefined) this.selectedEmployees = this.project.projectEmployees.map(e => e.employeeId)
      if (this.project.projectProducts !== null && this.project.projectProducts !== undefined) this.selectedProducts = this.project.projectProducts.map(e => e.productId)
    } 
  }
</script>